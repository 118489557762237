import { useEffect, useMemo, useState, useRef } from 'react'

import appContext from '../../context'
import utils from '../../utils'

import MainDisplayModule from '../../components/MainDisplayModule'
import ExpandedViewLabel from '../../components/ExpandedViewLabel'
import PocketContent from '../../components/PocketContent'
import CollapsibleContainer from '../../components/CollapsibleContainer'
import useMainDisplayModuleHistory from '../../hooks/useMainDisplayModuleHistory'
import useContentData from '../../hooks/useContentData'

import { ReactComponent as SurferSVG } from '../../svgs/surfer-icon.svg'
import { ReactComponent as ArrowLeftSVG } from '../../svgs/arrow-left-icon.svg'
import { ReactComponent as ArrowRightSVG } from '../../svgs/arrow-right-icon.svg'

import './style.css'

const DISPLAY_MODULE_ID = 'ContentList'

const { useAppContext } = appContext

const ContentList = () => {
  useContentData()

  const [ app_context ] = useAppContext()
  const {
    sync_id,
    data_indexes,
    data_indexes_sync_id,

    content_data,
    content_data_sync_id,

    display_history_initialized,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'sync_id',
        'data_indexes',
        'data_indexes_sync_id',

        'content_data',
        'content_data_sync_id',

        'display_history_initialized',
      ]
    })

  const { get: get_history, set: set_history } = useMainDisplayModuleHistory()
  const module_display_history = get_history({ id: DISPLAY_MODULE_ID })
  const { expanded: module_display_expanded } = module_display_history ?? {}

  const { content_archived, content_favorited } = data_indexes ?? {}

  // cache lookup maps and base freshness on sync id
  const [ sync_id_cache, set_sync_id_cache ] = useState({})
  const {
    sync_id: cached_sync_id,
    content_archived_map,
    content_favorited_map,
    tag_to_content_map,
  } = sync_id_cache ?? {}

  useEffect(() => {
    if (!sync_id || !content_data_sync_id || !data_indexes_sync_id) return
    if (sync_id !== content_data_sync_id) return
    if (sync_id !== data_indexes_sync_id) return

    if (Object.prototype.toString.call( content_archived ) !== '[object Array]') return
    if (Object.prototype.toString.call( content_favorited ) !== '[object Array]') return
    if (sync_id === cached_sync_id) return

    const next_cache = {}

    next_cache.content_archived_map = (content_archived ?? [])
      .reduce(( map, content_id ) => {
        map[ content_id ] = true
        return map
      }, {})

    next_cache.content_favorited_map = (content_favorited ?? [])
      .reduce(( map, content_id ) => {
        map[ content_id ] = true
        return map
      }, {})

    next_cache.tag_to_content_map = Object.keys( content_data?.user_tag_to_content ?? {} )
    .reduce(( map, tag_id ) => {
      map[ tag_id ] = content_data?.user_tag_to_content?.[ tag_id ]
        .reduce(( map, content_id ) => {
          map[ content_id ] = true
          return map
        }, {})
      return map
    }, {})

    set_sync_id_cache({
      sync_id,
      ...next_cache,
    })
  }, [
    cached_sync_id,
    sync_id,
    content_data_sync_id,
    data_indexes_sync_id,
    content_archived,
    content_favorited,
    content_data?.user_tag_to_content,
  ])

  // on init: create tag filters state
  const [ tagFilters, setTagFilters ] = useState()
  const tagFiltersArray = useMemo(() => {
    if (!tagFilters) return;
    return Object.keys( tagFilters ).filter( tag => tagFilters[ tag ] )
  }, [
    tagFilters
  ])

  useEffect(() => {
    if (!content_data_sync_id) return
    if (tagFilters) return

    const user_tags = content_data?.user_tags ?? []

    setTagFilters(
      user_tags.reduce(( map, tag ) => {
        map[ tag ] = false
        return map
      }, {})
    )
  }, [ content_data_sync_id, content_data, tagFilters ])

  // clear tag filters if untagged/"any tags" is selected
  const [ showUntagged, setShowUntagged ] = useState( false )
  const [ showAllTagged, setShowAllTagged ] = useState( false )
  useEffect(() => {
    if (!tagFiltersArray || tagFiltersArray.length === 0) return;

    if (showUntagged || showAllTagged) setTagFilters({})
  }, [ showUntagged, showAllTagged, tagFiltersArray ])

  const tag_filter_mode = showUntagged
    ? 'untagged'
    : showAllTagged
      ? 'any-tags'
      : tagFiltersArray?.length === 0
        ? 'no-filter'
        : tagFiltersArray?.length > 0
          ? 'tagged'
          : null

  const [ imageFilter, setImageFilter ] = useState( false )
  const [ videoFilter, setVideoFilter ] = useState( false )
  const [ archivedFilter, setArchivedFilter ] = useState( false )
  const [ favoritedFilter, setFavoritedFilter ] = useState( false )
  const [ queuedFilter, setQueuedFilter ] = useState( false )
  const [ titleFilter, setTitleFilter ] = useState( false )

  const [ sortMode, setSortMode ] = useState( 'random' )

  const [ pagination, setPagination ] = useState({ page_size: 3, offset: 0 })
  const { page_size, offset } = pagination

  const unshuffled_result_ids = useMemo(() => {
    if (!data_indexes || !content_data) return

    let unshuffled_result_ids = data_indexes.content

    switch (tag_filter_mode) {
      case 'any-tags':
        unshuffled_result_ids = unshuffled_result_ids.filter( content_id => content_data.content_to_user_tags?.[ content_id ] )
        break

      case 'untagged':
        unshuffled_result_ids = unshuffled_result_ids.filter( content_id => !content_data.content_to_user_tags?.[ content_id ] )
        break

      case 'tagged':
        tagFiltersArray.forEach( tag => {
          unshuffled_result_ids = unshuffled_result_ids.filter( result_id => tag_to_content_map?.[ tag ]?.[ result_id ] )
        })
        break

      case 'no-filter':
      default:
        // do nothing
        break
    }

    if (imageFilter) {
      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => content_data.content_to_images?.[ content_id ] )
    }

    if (videoFilter) {
      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => content_data.content_to_videos?.[ content_id ] )
    }

    if (archivedFilter) {
      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => content_archived_map?.[ content_id ] )
    }

    if (favoritedFilter) {
      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => content_favorited_map?.[ content_id ] )
    }

    if (queuedFilter) {
      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => !content_archived_map?.[ content_id ] )
    }

    if (titleFilter) {
      const title_search_token = titleFilter.toLowerCase()

      unshuffled_result_ids = unshuffled_result_ids.filter( content_id => {
        const save = content_data.content[ content_id ]
        if (!save.resolved_url) return false

        const display_title = save.resolved_title?.length > 0
          ? save.resolved_title
          : save.given_title?.length > 0
            ? save.given_title
            : save.resolved_url

        const title_search_space = display_title.toLowerCase()
        return title_search_space.indexOf( title_search_token ) > -1
      })
    }

    return unshuffled_result_ids
  }, [
    content_data,
    data_indexes,

    tag_filter_mode,
    imageFilter,
    videoFilter,
    archivedFilter,
    favoritedFilter,
    queuedFilter,
    titleFilter,
    tagFiltersArray,

    content_archived_map,
    content_favorited_map,
    tag_to_content_map,
  ])

  // reset pagination if changes are detected in:
  // - results
  // - sorting
  // - sync id
  // - results per page
  useEffect(() => {
    if (!sync_id || !unshuffled_result_ids || !sortMode || !page_size) return

    setPagination({
      page_size,
      offset: 0,
    })
  }, [
    sync_id,
    unshuffled_result_ids,
    page_size,
    sortMode,
  ])

  // shuffle order of results
  const [ content_result_ids, set_content_result_ids ] = useState()
  useEffect(() => {
    if (!unshuffled_result_ids || !content_data) return

    let shuffled_content_result_ids

    switch (sortMode) {
      case 'word-count-lowest':
      case 'word-count-highest':
        shuffled_content_result_ids = unshuffled_result_ids
          .map( id => content_data.content[ id ] )
          .sort(( a, b ) => {
            const a_wordcount = a.word_count
              ? parseInt( a.word_count )
              : 0

            const b_wordcount = b.word_count
              ? parseInt( b.word_count )
              : 0

            if (a_wordcount > b_wordcount)
              return sortMode === 'word-count-lowest'
                ? 1
                : -1

            if (a_wordcount < b_wordcount)
              return sortMode === 'word-count-lowest'
                ? -1
                : 1

            return 0
          })
          .map( content => content.item_id )
        break;

      case 'date-saved-newest':
      case 'date-saved-oldest':
        shuffled_content_result_ids = unshuffled_result_ids
          .map( id => content_data.content[ id ] )
          .sort(( a, b ) => {
            const a_timestamp = parseInt( content_data.user_content_states[ a.item_id ].time_added )
            const b_timestamp = parseInt( content_data.user_content_states[ b.item_id ].time_added )

            if (a_timestamp > b_timestamp)
              return sortMode === 'date-saved-newest'
                ? -1
                : 1

            if (a_timestamp < b_timestamp)
              return sortMode === 'date-saved-newest'
                ? 1
                : -1

            return 0
          })
          .map( content => content.item_id )
        break;

      case 'random':
      default:
        shuffled_content_result_ids = (
          unshuffled_result_ids
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value))
        break;
    }

    set_content_result_ids( shuffled_content_result_ids )
  }, [
    unshuffled_result_ids,
    content_data,
    sortMode, setSortMode,
  ])

  // cache results based on current surf settings
  const [ results_cache, set_results_cache ] = useState({})
  const {
    sync_id: results_cache_sync_id,
    tag_to_results_map,
    image_filter_results,
    video_filter_results,
    archived_filter_results,
    favorited_filter_results,
    queued_filter_results,
    untagged_filter_results,
    tagged_filter_results,
  } = results_cache
  useEffect(() => {
    if (!sync_id || !cached_sync_id || !unshuffled_result_ids) {

      if (results_cache_sync_id) set_results_cache({})
      return
    }

    const next_cache = {
      image_filter_results: [],
      video_filter_results: [],
      queued_filter_results: [],
      archived_filter_results: [],
      favorited_filter_results: [],
      untagged_filter_results: [],
      tagged_filter_results: [],
      tag_to_results_map: {},
    }

    unshuffled_result_ids
      .forEach( result_id => {
        if (!imageFilter && content_data.content_to_images?.[ result_id ]) next_cache.image_filter_results.push( result_id )
        if (!videoFilter && content_data.content_to_videos?.[ result_id ]) next_cache.video_filter_results.push( result_id )
        if (!queuedFilter && !content_archived_map?.[ result_id ]) next_cache.queued_filter_results.push( result_id )
        if (!archivedFilter && content_archived_map?.[ result_id ]) next_cache.archived_filter_results.push( result_id )
        if (!favoritedFilter && content_favorited_map?.[ result_id ]) next_cache.favorited_filter_results.push( result_id )

        if (tag_filter_mode !== 'untagged' && !content_data.content_to_user_tags?.[ result_id ]) next_cache.untagged_filter_results.push( result_id )
        if (tag_filter_mode !== 'tagged' && content_data.content_to_user_tags?.[ result_id ]) next_cache.tagged_filter_results.push( result_id )

        const result_tags = content_data.content_to_user_tags?.[ result_id ] ?? []

        result_tags?.forEach( result_tag => {
          if (!next_cache.tag_to_results_map[ result_tag ]) next_cache.tag_to_results_map[ result_tag ] = []

          next_cache.tag_to_results_map[ result_tag ].push( result_id )
        })
      })

    set_results_cache({
      sync_id,
      ...next_cache,
    })
  }, [
    sync_id, cached_sync_id,
    results_cache_sync_id,

    unshuffled_result_ids,
    imageFilter,
    videoFilter,
    archivedFilter,
    favoritedFilter,
    queuedFilter,
    tag_filter_mode,
    tagFiltersArray,

    content_data,
    content_archived_map,
    content_favorited_map,
  ])

  const content_to_display_ids = content_result_ids && content_result_ids.slice( offset, offset + page_size )
  const has_multiple_result_pages = content_result_ids && content_result_ids.length > page_size
  const current_page = offset / page_size
  const last_page = content_result_ids && (Math.ceil( content_result_ids.length / page_size ) - 1)

  const pagination_controls = has_multiple_result_pages
    && (
      <div className='PaginationControls'>
        <span className='ViewPreviousResults'>
          {
            current_page > 0
              ? <ArrowLeftSVG
                  onClick={
                    () => {
                      setPagination({
                        offset: offset - page_size,
                        page_size,
                      })
                    }
                  }
                />
              : null
            }
        </span>
        <span
          style={{
            color: '#ccc',
            padding: '.25em',
            cursor: 'pointer',
            borderRadius: '.5em',
            textDecorationColor: 'orange',
            textDecorationThickness: '1px',
            textDecorationLine: 'underline',
          }}
          onClick={
            () => {
              const requested_page = prompt( `[ total pages = ${ last_page + 1 } ][ current page = ${ current_page + 1 } ][ go to page = ? ]`, ( current_page + 1 ) )
              try {
                const requested_page_int = parseInt( requested_page )

                if (Number.isNaN(requested_page_int)) throw new Error( 'requested page must be a number' )
                if (requested_page_int < 1) throw new Error( 'requested page must be greater than 1' )
                if (requested_page_int > ( last_page + 1 )) throw new Error( `requested page cannot be greater than ${ last_page + 1 }` )

                  setPagination({
                    offset: page_size * (requested_page_int - 1),
                    page_size,
                  })
              }
              catch( go_to_requested_page_error ){
                console.log( `action=change-surf-page current_page=${ current_page + 1 } requested_page=${ requested_page } success=false error="${ go_to_requested_page_error.message }"` )
              }
            }
          }
        >
          { current_page + 1 }
        </span>
        <span className='ViewNextResults'>
          {
            current_page < last_page
              ? <ArrowRightSVG
                  onClick={
                    () => {
                      setPagination({
                        offset: offset + page_size,
                        page_size,
                      })
                    }
                  }
                />
              : null
          }
        </span>
      </div>
    )

  const inline_details_array = useMemo(() => {
    const inline_details_array = []

    if (queuedFilter) inline_details_array.push( 'queued' )
    if (archivedFilter) inline_details_array.push( 'archived' )
    if (favoritedFilter) inline_details_array.push( 'favorited' )
    if (tag_filter_mode === 'any-tags') inline_details_array.push( 'has at least one tag' )
    if (tag_filter_mode === 'untagged') inline_details_array.push( 'untagged' )
    if (imageFilter) inline_details_array.push( 'has images' )
    if (videoFilter) inline_details_array.push( 'has videos' )
    if (tag_filter_mode === 'tagged') inline_details_array.push( `tagged "${ tagFiltersArray.join( '", "' ) }"` )
    if (titleFilter) inline_details_array.push( `title contains "${ titleFilter }"` )

    return inline_details_array
  }, [
    tag_filter_mode,
    queuedFilter,
    archivedFilter,
    favoritedFilter,
    imageFilter,
    videoFilter,
    titleFilter,
    tagFiltersArray,
  ])

  const has_active_filter = queuedFilter
    || archivedFilter
    || favoritedFilter
    || imageFilter
    || videoFilter
    || titleFilter
    || tag_filter_mode !== 'no-filter'

  const required_dataset_loaded = (
    data_indexes
      && display_history_initialized
      && sync_id_cache
  )

  const ready_to_surf = (
    content_data
      && content_data.content
      && results_cache_sync_id
  )

  return (
    !required_dataset_loaded
      ? null
      : <MainDisplayModule
          id="ContentList"
          label="surf"
          inline={
            <>
              {
                content_data
                  ? <>
                      { `viewing ${ has_active_filter ? unshuffled_result_ids.length : 'all' } ${ unshuffled_result_ids.length === 1 ? 'save' : 'saves' }` }
                      {
                        has_active_filter
                        && (
                          <>
                            { ' - ' }
                            { inline_details_array.join( ', ' ) }
                          </>
                        )
                      }
                    </>
                  : 'loading content data ...'
              }
            </>
          }
          expanded={
            <>
              <div className='Title'>
                <span className='Icon'>
                  <SurferSVG fill="#000" height="1.1em" />
                </span>
                <span className='Label'>surf saves</span>
              </div>
              {
                ready_to_surf && (
                  <>
                    <div className='' style={{ marginTop: '2em', fontWeight: 900, color: '#ccc', fontSize: '1.5em', letterSpacing: '-1px' }}>
                      { unshuffled_result_ids.length }
                      { ` ${ unshuffled_result_ids.length === 1 ? 'save' : 'saves' }` }
                      {
                        has_active_filter && <span style={{ display: 'block', fontSize: '.64em', fontWeight: 300, letterSpacing: '0px', color: '#aaa' }}> ({ ((unshuffled_result_ids.length / data_indexes.content.length) * 100).toFixed(1) }% of all saves)</span>
                      }
                    </div>
                    {
                      has_active_filter && (
                        <div className='FiltersList'>
                          <div>active filters:</div>
                          {
                            titleFilter && (
                              <ActiveFilterToggle
                                key={ 'title-search' }
                                category={ 'title contains' }
                                current_value={ titleFilter }
                                onClick={ () => setTitleFilter( false ) }
                              />
                            )
                          }
                          {
                            queuedFilter && (
                              <ActiveFilterToggle
                                key={ 'pocket-status-queued' }
                                category={ 'pocket status' }
                                current_value={ 'queued' }
                                onClick={ () => setQueuedFilter( false ) }
                              />
                            )
                          }
                          {
                            archivedFilter && (
                              <ActiveFilterToggle
                                key={ 'pocket-status-archived' }
                                category={ 'pocket status' }
                                current_value={ 'archived' }
                                onClick={ () => setArchivedFilter( false ) }
                              />
                            )
                          }
                          {
                            favoritedFilter && (
                              <ActiveFilterToggle
                                key={ 'pocket-status-favorited' }
                                category={ 'pocket status' }
                                current_value={ 'favorited' }
                                onClick={ () => setFavoritedFilter( false ) }
                              />
                            )
                          }
                          {
                            imageFilter && (
                              <ActiveFilterToggle
                                key={ 'media-attachments-images' }
                                category={ 'media attachments' }
                                current_value={ 'has images' }
                                onClick={ () => setImageFilter( false ) }
                              />
                            )
                          }
                          {
                            videoFilter && (
                              <ActiveFilterToggle
                                key={ 'media-attachments-videos' }
                                category={ 'media attachments' }
                                current_value={ 'has videos' }
                                onClick={ () => setVideoFilter( false ) }
                              />
                            )
                          }
                          {
                            tag_filter_mode === 'any-tags' && (
                              <ActiveFilterToggle
                                key={ 'tagged-status-has-tags' }
                                category={ 'tagged status' }
                                current_value={ 'has tags' }
                                onClick={ () => setShowAllTagged( false ) }
                              />
                            )
                          }
                          {
                            tag_filter_mode === 'untagged' && (
                              <ActiveFilterToggle
                                key={ 'tagged-status-untagged' }
                                category={ 'tagged status' }
                                current_value={ 'untagged' }
                                onClick={ () => setShowUntagged( false ) }
                              />
                            )
                          }
                          {
                            tag_filter_mode === 'tagged' && (
                              tagFiltersArray.map( user_tag =>
                                <ActiveFilterToggle
                                  key={ `tagged-${ user_tag }` }
                                  category={ 'tagged' }
                                  current_value={ user_tag }
                                  onClick={() => setTagFilters({ ...tagFilters, [ user_tag ]: false, })}
                                />
                              )
                            )
                          }
                        </div>
                      )
                    }
                    <div style={{ marginTop: '3em' }}>filter by:</div>
                    <div
                      style={{
                        overflow: 'hidden',
                      }}
                    >
                      <CollapsibleContainer
                        toggle={ <ExpandedViewLabel title='title' className={ titleFilter ? ' active' : '' } /> }
                        children={
                          <>
                            <TitleSearch
                              titleFilter={ titleFilter }
                              onChange={
                                event => {
                                  setTitleFilter( event.target.value.length > 0 ? event.target.value : false )
                                }
                              }
                            />
                          </>
                        }
                        initialize_collapsed={ true }
                      />
                      <CollapsibleContainer
                        toggle={ <ExpandedViewLabel title='pocket status' className={ (archivedFilter || queuedFilter || favoritedFilter) ? ' active' : '' } /> }
                        children={
                          <>
                            <span
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                marginRight: '1em',
                                color: (
                                  archivedFilter
                                    ? 'orange'
                                    : archived_filter_results.length === 0
                                      ? '#ccc'
                                      : 'inherit'
                                )
                              }}
                              onClick={
                                () => {
                                  if (queuedFilter && !archivedFilter) setQueuedFilter( false )
                                  setArchivedFilter( !archivedFilter )
                                }
                              }
                            >
                              <span style={{ fontSize: '.85em' }}>archived</span>
                              {
                                !archivedFilter
                                  ? <>
                                      { ' ' }
                                      <span style={{ fontSize: '.85em', color: '#aaa' }}>({ archived_filter_results.length })</span>
                                    </>
                                  : null
                              }
                            </span>
                            <span
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                marginRight: '1em',
                                color: (
                                  favoritedFilter
                                    ? 'orange'
                                    : favorited_filter_results.length === 0
                                      ? '#ccc'
                                      : 'inherit'
                                )
                              }}
                              onClick={
                                () => setFavoritedFilter( !favoritedFilter )
                              }
                            >
                              <span style={{ fontSize: '.85em' }}>favorited</span>
                              {
                                !favoritedFilter
                                  ? <>
                                      { ' ' }
                                      <span style={{ fontSize: '.85em', color: '#aaa' }}>({ favorited_filter_results.length })</span>
                                    </>
                                  : null
                              }
                            </span>
                            <span
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                marginRight: '1em',
                                color: (
                                  queuedFilter
                                    ? 'orange'
                                    : queued_filter_results.length === 0
                                      ? '#ccc'
                                      : 'inherit'
                                )
                              }}
                              onClick={
                                () => {
                                  if (archivedFilter && !queuedFilter) setArchivedFilter( false )
                                  setQueuedFilter( !queuedFilter )
                                }
                              }
                            >
                              <span style={{ fontSize: '.85em' }}>queued</span>
                              {
                                !queuedFilter
                                  ? <>
                                      { ' ' }
                                      <span style={{ fontSize: '.85em', color: '#aaa' }}>({ queued_filter_results.length })</span>
                                    </>
                                  : null
                              }
                            </span>
                          </>
                        }
                        initialize_collapsed={ true }
                      />
                      <CollapsibleContainer
                        toggle={ <ExpandedViewLabel title='media attachments' className={ (imageFilter || videoFilter) ? ' active' : '' } /> }
                        children={
                          <>
                            <span
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                marginRight: '1em',
                                color: (
                                  imageFilter
                                    ? 'orange'
                                    : image_filter_results.length === 0
                                      ? '#ccc'
                                      : 'inherit'
                                )
                              }}
                              onClick={
                                () => setImageFilter( !imageFilter )
                              }
                            >
                              <span style={{ fontSize: '.85em' }}>has images</span>
                              {
                                !imageFilter
                                  ? <>
                                      { ' ' }
                                      <span style={{ fontSize: '.85em', color: '#aaa' }}>({ image_filter_results.length })</span>
                                    </>
                                  : null
                              }
                            </span>
                            <span
                              style={{
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                marginRight: '1em',
                                color: (
                                  videoFilter
                                    ? 'orange'
                                    : video_filter_results.length === 0
                                      ? '#ccc'
                                      : 'inherit'
                                )
                              }}
                              onClick={
                                () => setVideoFilter( !videoFilter )
                              }
                            >
                              <span style={{ fontSize: '.85em' }}>has videos</span>
                              {
                                !videoFilter
                                  ? <>
                                      { ' ' }
                                      <span style={{ fontSize: '.85em', color: '#bbb' }}>({ video_filter_results.length })</span>
                                    </>
                                  : null
                              }
                            </span>
                          </>
                        }
                        initialize_collapsed={ true }
                      />
                      <CollapsibleContainer
                        toggle={ <ExpandedViewLabel title='tagged status' className={ (showUntagged || showAllTagged) ? ' active': '' } /> }
                        children={
                          <>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '1.25em',
                                marginBottom: '.75em',
                                fontSize: '0.85em',
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                cursor: 'pointer',
                                color: (
                                  tag_filter_mode === 'any-tags'
                                    ? 'orange'
                                    : tag_filter_mode === 'tagged'
                                      ? 'inherit'
                                        : tag_filter_mode === 'no-filter'
                                          ? content_result_ids?.length > 0
                                            ? 'inherit'
                                            : '#ccc'
                                          : '#ccc'
                                ),
                              }}
                              onClick={
                                () => {
                                  setShowAllTagged( !showAllTagged )
                                  if (!showAllTagged) setShowUntagged( false )
                                }
                              }
                            >
                              has tags
                              {
                                tag_filter_mode !== 'any-tags'
                                && (
                                  <>
                                    { ` ` }
                                    <span
                                      style={{
                                        color: '#aaa'
                                      }}
                                    >
                                      ({
                                        tag_filter_mode === 'any-tags'
                                         ? content_result_ids.length
                                         : tagFiltersArray?.length
                                            ? content_result_ids.length
                                            : tagged_filter_results.length
                                      })
                                    </span>
                                  </>
                                )
                              }
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                marginRight: '1.25em',
                                marginBottom: '.75em',
                                fontSize: '0.85em',
                                textDecorationLine: 'underline',
                                textDecorationThickness: '1px',
                                textDecorationColor: 'orange',
                                cursor: 'pointer',
                                color: (
                                  tag_filter_mode === 'untagged'
                                    ? 'orange'
                                    : tag_filter_mode === 'any-tags'
                                      ? '#ccc'
                                      : tag_filter_mode === 'tagged'
                                        ? '#ccc'
                                        : content_result_ids?.length > 0
                                          ? 'inherit'
                                          : '#ccc'
                                ),
                              }}
                              onClick={
                                () => {
                                  setShowUntagged( !showUntagged )
                                  if (!showUntagged) setShowAllTagged( false )
                                }
                              }
                            >
                              untagged
                              {
                                !showUntagged
                                && (
                                  <>
                                    { ` ` }
                                    <span
                                      style={{
                                        color: '#aaa',
                                      }}
                                    >
                                      ({
                                        tagFiltersArray?.length === 0
                                         ? untagged_filter_results.length
                                         : 0
                                      })
                                    </span>
                                  </>
                                )
                              }
                            </span>
                          </>
                        }
                        initialize_collapsed={ true }
                      />
                      {
                        content_data
                        && content_data.user_tags
                        && content_data.user_tags.length > 0
                        && (
                          <CollapsibleContainer
                            toggle={ <ExpandedViewLabel title='user tags' className={ tagFiltersArray?.length > 0 ? ' active' : '' } /> }
                            children={
                              <>
                                <FilterOptionsList
                                  options={ content_data.user_tags }
                                  current_value={ tagFilters }
                                  options_per_page={ content_data.user_tags.length }
                                  render_option={
                                    item => {
                                      return (
                                        <div
                                          key={ item }
                                          style={{
                                            display: 'inline-block',
                                            marginRight: '1.25em',
                                            marginBottom: '.75em',
                                            fontSize: '0.85em',
                                            textDecorationLine: 'underline',
                                            textDecorationThickness: '1px',
                                            textDecorationColor: 'orange',
                                            cursor: 'pointer',
                                            color: tagFilters?.[ item ]
                                              ? 'orange'
                                              : tag_to_results_map?.[ item ]?.length > 0
                                                ? '#000'
                                                : '#ccc'
                                          }}
                                          onClick={
                                            () => {
                                              if (showUntagged) setShowUntagged( false )
                                              if (showAllTagged) setShowAllTagged( false )
                                              setTagFilters({
                                                ...tagFilters,
                                                [ item ]: !tagFilters?.[ item ],
                                              })
                                            }
                                          }
                                        >
                                          { item }
                                          {
                                            !tagFilters?.[ item ]
                                            && (
                                              <>
                                                { ' ' }
                                                <span style={{ color: '#aaa' }}>
                                                  { `(${ tag_to_results_map?.[ item ]?.length ?? 0 })` }
                                                </span>
                                              </>
                                            )
                                          }
                                        </div>
                                      )
                                    }
                                  }
                                />
                              </>
                            }
                            initialize_collapsed={ true }
                          />
                        )
                      }
                    </div>
                    <div
                      style={{
                        display: 'block',
                        textAlign: 'right',
                        marginBottom: '3em',
                        marginTop: '2em',
                        overflow: 'hidden',
                      }}
                    >
                      <span>sort by:</span>
                      { ' ' }
                      <select
                        id='sort-strategy'
                        value={ sortMode }
                        onChange={
                          (e) => setSortMode( e.target.value )
                        }
                      >
                        <option value='random'>random</option>
                        <option value='date-saved-newest'>saved - newest</option>
                        <option value='date-saved-oldest'>saved - oldest</option>
                        <option value='word-count-lowest'>word count - lowest</option>
                        <option value='word-count-highest'>word count - highest</option>
                      </select>
                    </div>
                    { pagination_controls }
                    {
                      content_to_display_ids
                      && content_to_display_ids
                        .map( content_id => {
                          const save = content_data.content[ content_id ]
                          if (!save) return null

                          const user_state = content_data.user_content_states[ content_id ]

                          const save_images = content_data?.content_to_images?.[ content_id ] ?? null
                          const save_videos = content_data?.content_to_videos?.[ content_id ] ?? null
                          const save_tags = content_data?.content_to_user_tags?.[ content_id ] ?? null

                          const pocket_domain = save.domain_id && content_data.domains?.[ save.domain_id ]

                          return (
                            <PocketContent
                              key={ content_id }
                              save={ save }
                              user_state={ user_state }
                              domain={ pocket_domain }
                              images={ save_images && save_images.map( image_id => content_data.images?.[ image_id ] ) }
                              videos={ save_videos }
                              tags={ save_tags }
                            />
                          )
                        })
                    }
                    { pagination_controls }
                  </>
                )
              }
            </>
          }
          initialIsInline={ !module_display_expanded }
          onDisplayHistoryUpdate={
            ({ expanded: updated_expanded }) => {
              set_history({ id: DISPLAY_MODULE_ID, expanded: updated_expanded })
            }
          }
        />
  )
}

const ActiveFilterToggle = ({ category, current_value, onClick }) => {
  return (
    <div
      className='ActiveFilterToggle'
      onClick={ onClick }
    >
      <ActiveFilterToggleLabel
        category={ category }
        current_value={ current_value }
      />
    </div>
  )
}

const ActiveFilterToggleLabel = ({ category, current_value }) => {
  return (
    <span className='Label'>
      <span className='Category'>
        { `${ category }: ` }
      </span>
      <span className='CurrentValue'>
        { current_value }
      </span>
    </span>
  )
}

const FilterOptionsList = ({ options, current_value, onClick, options_per_page = 10, render_option = (item) => <div key={ item }>{ item }</div> }) => {
  const [ sorted_options_wrapper, set_sorted_options ] = useState()
  const { sorted: sorted_options } = sorted_options_wrapper ?? {}

  const stringified_options = JSON.stringify( options ?? [] )

  // sort options alphabetically
  useEffect(() => {
    if (!options) return

    if (sorted_options_wrapper) {
      if (sorted_options_wrapper.source === options) return

      console.log( 'action=log-stringification location=sort-options-alphabetically' )
      if (stringified_options === sorted_options_wrapper.source_stringified ) return
    }

    set_sorted_options({
      source: options,
      source_stringified: stringified_options,
      sorted: options.sort(),
    })
  }, [
    options,
    stringified_options,
    sorted_options_wrapper,
  ])

  const [ search_keyword, set_search_keyword ] = useState()

  // filter options by search keyword
  const [ filtered_sorted_options_wrapper, set_filtered_sorted_options ] = useState()
  const {
    options: filtered_sorted_options,
    keyword: filter_keyword,
  } = filtered_sorted_options_wrapper ?? {}

  useEffect(() => {
    if ((filter_keyword === search_keyword) && filtered_sorted_options) return;

    const normalized_search_keyword = search_keyword?.toLowerCase()

    const filtered_options = search_keyword
      ? sorted_options.filter( tag => tag.indexOf( normalized_search_keyword ) > -1 )
      : sorted_options

    set_filtered_sorted_options({
      options: filtered_options,
      keyword: search_keyword,
    })
  }, [
    filter_keyword,
    search_keyword,
    sorted_options,
    filtered_sorted_options,
  ])

  const [ pagination, set_pagination ] = useState()
  const { current_page } = pagination ?? {}

  // set initial pagination values
  useEffect(() => {
    if (pagination) return

    const has_multiple_pages = options && options.length > options_per_page

    set_pagination({
      current_page: 1,
      has_multiple_pages,
      last_page: has_multiple_pages
        ? Math.ceil( options.length / options_per_page )
        : 1
    })
  }, [
    options,
    pagination,
    stringified_options,
    options_per_page,
  ])

  // load items to be rendered on current page
  const [ current_page_items, set_current_page_items ] = useState()
  useEffect(() => {
    if (!current_page || !filtered_sorted_options) return;

    const current_page_first_item_index = ( current_page - 1 ) * options_per_page
    const current_page_items = (filtered_sorted_options ?? []).slice( current_page_first_item_index, current_page_first_item_index + options_per_page  )

    set_current_page_items( current_page_items )
  }, [
    current_page,
    options_per_page,
    filtered_sorted_options,
  ])

  return (
    !current_page_items
      ? <div>loading...</div>
      : <>
          <div
            style={{
              margin: '.64em 0',
            }}
          >
            <input
              style={{
                padding: '.25em .5em',
              }}
              placeholder='search tags...'
              onChange={
                event => set_search_keyword( event.target.value )
              }
            />
          </div>
          {
            current_page_items
              .map( item => render_option( item ) )
          }
        </>
  )
}

const TitleSearch = ({ onChange, titleFilter }) => {
  const input_ref = useRef( null )

  useEffect(() => {
    input_ref?.current?.focus?.()
  }, [])

  return (
    <div
      style={{
        margin: '.64em 0',
      }}
    >
      <input
        ref={ input_ref }
        style={{
          padding: '.25em .5em',
        }}
        placeholder='search title...'
        onChange={ onChange ?? (() => {}) }
        value={ typeof titleFilter === 'string' ? titleFilter : '' }
      />
    </div>
  )
}

export default ContentList
