import { useMemo } from 'react'

import appContext from '../../context'
import utils from '../../utils'

import MainDisplayModule from '../../components/MainDisplayModule'
import ExpandedViewItemGroup from '../../components/ExpandedViewItemGroup'
import ExpandedViewItem from '../../components/ExpandedViewItem'
import useDataIndexes from '../../hooks/useDataIndexes'
import useMainDisplayModuleHistory from '../../hooks/useMainDisplayModuleHistory'

import { ReactComponent as BarChartSVG } from '../../svgs/bar-chart-icon.svg'
import { ReactComponent as ArchivesSVG } from '../../svgs/archives-icon.svg'
import { ReactComponent as BookmarkSVG } from '../../svgs/bookmark-icon.svg'
import { ReactComponent as FavoriteSVG } from '../../svgs/favorite-icon.svg'
import { ReactComponent as GlobeSVG } from '../../svgs/globe-icon.svg'
import { ReactComponent as ImageSVG } from '../../svgs/image-icon.svg'
import { ReactComponent as InboxSVG } from '../../svgs/inbox-icon.svg'
import { ReactComponent as VideoSVG } from '../../svgs/video-icon.svg'
import { ReactComponent as WriteSVG } from '../../svgs/write-icon.svg'

import './style.css'

const DISPLAY_MODULE_ID = 'ContentStats'

const { useAppContext } = appContext

const ContentStats = () => {
  useDataIndexes()

  const [ app_context ] = useAppContext()
  const {
    sync_id,
    data_indexes,
    content_data,
    display_history_initialized,
  } =
    utils.app_context.pluck({
      app_context,
      keys_to_pluck: [
        'sync_id',
        'data_indexes',
        'content_data',
        'display_history_initialized',
      ]
    })

  const { get: get_history, set: set_history } = useMainDisplayModuleHistory()

  const display_history = get_history({ id: DISPLAY_MODULE_ID })
  const { expanded: module_expanded } = display_history ?? {}

  const savesCount = data_indexes?.content?.length ?? 0
  const archivedSavesCount = data_indexes?.content_archived?.length ?? 0
  const favoriteSavesCount = data_indexes?.content_favorited?.length ?? 0
  const taggedSavesCount = data_indexes?.content_tagged?.length ?? 0
  const queuedSavesCount = savesCount - archivedSavesCount

  const queued_percent = Math.round(( queuedSavesCount / savesCount ) * 100 )
  const favorite_percent = Math.round(( favoriteSavesCount / savesCount ) * 100 )
  const tagged_percent = Math.round(( taggedSavesCount / savesCount ) * 100 )

  const words_read = data_indexes?.content_archived?.reduce(( words_read, content_id ) => {
    const content = content_data?.content?.[ content_id ]
    if (!content) return words_read

    return words_read + (content?.word_count ? parseInt( content.word_count ) : 0 )
  }, 0 )

  const today_date = new Date()
  const today_epoch = today_date.getTime()
  const week_ago_date = new Date( today_epoch )
  week_ago_date.setHours( 0, 0, 0, 0 )
  week_ago_date.setDate( today_date.getDate() - 6 )

  const week_ago_epoch = week_ago_date.getTime()
  const week_ago_stats = useMemo(() => {
    const stats = {
      saved: [],
      read: [],
      favorited: [],
      word_count: 0,
    }

    data_indexes?.content?.forEach( content_id => {
      const user_content_state = content_data?.user_content_states?.[ content_id ]

      const saved_epoch = Math.round( user_content_state?.time_added * 1000)
      if (saved_epoch > week_ago_epoch) stats.saved.push( content_id )

      const read_epoch = Math.round( user_content_state?.time_read * 1000)
      if (read_epoch > week_ago_epoch) stats.read.push( content_id )

      const favorited_epoch = Math.round( user_content_state?.time_favorited * 1000)
      if (favorited_epoch > week_ago_epoch) stats.favorited.push( content_id )
    })

    stats.read.forEach( content_id => {
      const content = content_data?.content?.[ content_id ]
      if (!content) return

      stats.word_count += (content?.word_count ? parseInt( content.word_count ) : 0)
    })

    return stats
  }, [
    week_ago_epoch,
    data_indexes?.content,
    content_data?.content,
    content_data?.user_content_states,
  ])

  return (
    (display_history_initialized && sync_id)
      ? <MainDisplayModule
          id="ContentStats"
          label="stats"
          inline={
            (() => {
              return <>
                {
                  !data_indexes && 'loading ...'
                }
                {
                  data_indexes &&
                  `${ savesCount } saves - ${ queuedSavesCount } queued, ${ archivedSavesCount } archived`
                }
              </>
            })()
          }
          expanded={
            <>
              <div className='Title'>
                <span className='Icon'>
                  <BarChartSVG />
                </span>
                { 'pocket statistics' }
              </div>
              {
                data_indexes && (
                  <>
                    <ExpandedViewItemGroup>
                      <FocalStatBlock>
                        <FocalStat className='SavesCount'>
                          <div className='Stat'>
                            { (new Intl.NumberFormat()).format( savesCount ) }
                          </div>
                          <div className='Label'>
                            { savesCount === 1 ? 'save' : 'saves' }
                          </div>
                        </FocalStat>
                        <FocalStat className='WordsRead'>
                          <div className='Stat'>
                            { (new Intl.NumberFormat('en-US', { notation: 'compact' })).format( words_read ) }
                          </div>
                          <div className='Label'>
                            { words_read === 1 ? 'word' : 'words' }
                            { ' read' }
                          </div>
                        </FocalStat>
                        <FocalStat>
                          <div className='Stat'>
                            { (new Intl.NumberFormat('en-US', { notation: 'compact' })).format( week_ago_stats.saved.length ) }
                          </div>
                          <div className='Label'>
                            { week_ago_stats.saved.length === 1 ? 'save' : 'saves' }
                            <br />
                            { 'in the last' }
                            <br />
                            { 'seven days' }
                          </div>
                        </FocalStat>
                        <FocalStat>
                          <div className='Stat'>
                            { (new Intl.NumberFormat('en-US', { notation: 'compact' })).format( week_ago_stats.word_count ) }
                          </div>
                          <div className='Label'>
                            { week_ago_stats.word_count === 1 ? 'word' : 'words' }
                            { ' read' }
                            <br />
                            { 'in the last' }
                            <br />
                            { 'seven days' }
                          </div>
                        </FocalStat>
                      </FocalStatBlock>
                      <ExpandedViewItem
                        icon={ <InboxSVG /> }
                        label={ `${ savesCount - archivedSavesCount } queued` }
                      />
                      <ExpandedViewItem
                        className={ 'align-right' }
                        icon={ <ArchivesSVG /> }
                        label={ `${ archivedSavesCount } archived` }
                      />
                      <StatsPercentBar percent={ queued_percent } percent_color='orange' alt_color='gold' />
                      <ExpandedViewItem
                        icon={ <FavoriteSVG /> }
                        label={
                          favoriteSavesCount === 1
                            ? '1 favorite'
                            : `${ favoriteSavesCount } favorites`
                        }
                      />
                      <StatsPercentBar
                        percent={ favorite_percent }
                        show_alt_percent={ false }
                      />
                      <ExpandedViewItem
                        icon={ <BookmarkSVG /> }
                        label={ `${ taggedSavesCount } tagged` }
                      />
                      <StatsPercentBar
                        percent={ tagged_percent }
                        show_alt_percent={ false }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="about user">
                      <ExpandedViewItem
                        icon={ <BookmarkSVG /> }
                        label={
                          data_indexes?.tags?.length === 1
                            ? '1 tag'
                            : `${ content_data?.user_tags?.length ?? 0 } tags`
                        }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="sources of saves">
                      <ExpandedViewItem
                        icon={ <WriteSVG /> }
                        label={
                          data_indexes?.authors?.length === 1
                            ? '1 author'
                            : `${ data_indexes?.authors?.length ?? 0 } authors`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <GlobeSVG /> }
                        label={
                          data_indexes?.domains?.length === 1
                            ? '1 domain'
                            : `${ data_indexes?.domains?.length ?? 0 } domains`
                        }
                      />
                    </ExpandedViewItemGroup>
                    <ExpandedViewItemGroup title="media in saves">
                      <ExpandedViewItem
                        icon={ <ImageSVG /> }
                        label={
                          data_indexes?.images?.length === 1
                            ? '1 image'
                            : `${ data_indexes?.images?.length ?? 0 } images`
                        }
                      />
                      <ExpandedViewItem
                        icon={ <VideoSVG /> }
                        label={
                          data_indexes?.videos?.length === 1
                            ? '1 video'
                            : `${ data_indexes?.videos?.length ?? 0 } videos`
                        }
                      />
                    </ExpandedViewItemGroup>
                  </>
                )
              }
            </>
          }
          initialIsInline={ !module_expanded }
          onDisplayHistoryUpdate={
            ({ expanded: updated_expanded }) => {
              set_history({ id: DISPLAY_MODULE_ID, expanded: updated_expanded })
            }
          }
        />
      : null
  )
}

const FocalStatBlock = ({ className, children }) => {
  const classname_to_use = `FocalStatBlock${ className ? ` ${ className }` : '' }`
  return (
    <div className={ classname_to_use }>{ children }</div>
  )
}

const FocalStat = ({ className, children }) => {
  const classname_to_use = `FocalStat${ className ? ` ${ className }` : '' }`
  return (
    <div className={ classname_to_use }>{ children }</div>
  )
}

const StatsPercentBar = ({ className, percent, percent_color = 'gold', alt_color = '#ddd', show_alt_percent = true }) => {
  const alt_percent = 100 - percent
  return (
    <div className={ `StatsPercentBar${ className ? ` ${ className }` : '' }` }>
      <span className='Target' style={{ width: `${ percent }%`, backgroundColor: percent_color }}>
        { percent }%
      </span>
      <span className='Alt' style={{ width: `${ alt_percent }%`, backgroundColor: alt_color }}>
        { show_alt_percent ? `${ alt_percent }%` : ''  }
      </span>
    </div>
  )
}

export default ContentStats
